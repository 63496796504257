<template>
  <div>
    <div class="menubox" v-if="$route.meta.menus">
      <el-menu
        :default-active="actives"
        class="el-menu-vertical-demo"
        @select="menuSelect"
        router
        background-color="#ffffff"
        text-color="#333333"
        active-text-color="#e01811"
      >
        <div>
          <el-menu-item
            v-for="(item, index) in menuList"
            :key="index"
            :index="item.value"
          >
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </div>
        <!-- <el-menu-item index="2">
        <span slot="title">事业单位招聘公告</span>
      </el-menu-item>
      <el-menu-item index="3">
        <span slot="title">其他招聘公告</span>
      </el-menu-item>
      <el-menu-item index="4">
        <span slot="title">网上报名</span>
      </el-menu-item> -->
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          title: "招聘公告",
          value: "/affichebm?title=招聘公告",
        },
        {
          title: "事业单位招聘公告",
          value: "/affichebm?title=事业单位招聘公告",
        },
        {
          title: "其他招聘公告",
          value: "/affichebm?title=其他招聘公告",
        },
        {
          title: "网上报名",
          value: "/affichebm?title=网上报名",
        },
        {
          title: "事业单位网上报名",
          value: "/affichebm?title=事业单位网上报名",
        },
        {
          title: "其他网上报名",
          value: "/affichebm?title=其他网上报名",
        },
        {
          title: "准考证打印",
          value: "/affichebm?title=准考证打印",
        },
        {
          title: "成绩查询",
          value: "/affichebm?title=成绩查询",
        },
        {
          title: "相关通知",
          value: "/affichebm?title=相关通知",
        },
        {
          title: "事业单位相关通知",
          value: "/affichebm?title=事业单位相关通知",
        },
        {
          title: "其他相关通知",
          value: "/affichebm?title=其他相关通知",
        },
        {
          title: "聘用公示",
          value: "/affichebm?title=聘用公示",
        },
        {
          title: "事业单位聘用公示",
          value: "/affichebm?title=事业单位聘用公示",
        },
        {
          title: "其他聘用公示",
          value: "/affichebm?title=其他聘用公示",
        },
        {
          title: "考试报名延长时间",
          value: "/affichebm?title=考试报名延长时间",
        },
      ],
      actives: "/affichebm?title=招聘公告",
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        // console.log(newValue.query.title, "newValue新数据1111111111 ");
        // console.log(oldValue, "oldValue旧数据1111111111111");
        this.actives = "/affichebm?title=" + newValue.query.title;
      },
    },
  },
  mounted() {},
  methods: {
    menuSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.menubox {
  margin-right: 30px;
  width: 300px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #ffffff;
}
</style>

<style>
.el-menu {
  border: 0 !important;
}
.el-menu-item {
  margin: 8px 0;
  height: 40px !important;
  line-height: 40px !important;
}
.el-menu-item:nth-child(1),
.el-menu-item:nth-child(4),
.el-menu-item:nth-child(7),
.el-menu-item:nth-child(8),
.el-menu-item:nth-child(9),
.el-menu-item:nth-child(12),
.el-menu-item:nth-child(15) {
  background-color: #e01811 !important;
  color: #ffffff !important;
}
.el-submenu__title:hover,
.el-menu-item.is-active,
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #ffbebc !important;
  color: #e01811 !important;
}
</style>