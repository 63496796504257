import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    meta: {
      title: '首页',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/home/home.vue')
  },
  {
    // 招聘公告
    path: '/affiche',
    name: 'Affiche',
    meta: {
      title: '东莞市报名考试系统',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/affiche/affichepage.vue')
  },
  {
    // 招聘公告
    path: '/notice',
    name: 'Notice',
    meta: {
      title: '事业单位招聘公告',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/notice/index.vue')
  }, {
    // 产品详情
    path: '/adventImg',
    name: 'AdventImg',
    meta: {
      title: '产品详情',
      keepAlive: true, // 需要被缓存
      menus: false, //需不需要左侧的导航栏
    },
    component: () => import('@/views/adventImg/index.vue')
  },
  {
    // 其他招聘公告
    path: '/noticeOther',
    name: 'NoticeOther',
    meta: {
      title: '其他招聘公告',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/noticeOther/index.vue')
  },

  {
    // 网上报名
    path: '/signup',
    name: 'Signup',
    meta: {
      title: '网上报名',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/signup/index.vue')
  },
  {
    // 事业单位网上报名
    path: '/workSign',
    name: 'WorkSign',
    meta: {
      title: '事业单位网上报名',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/workSign/index.vue')
  },
  {
    // 其他网上报名
    path: '/signupOther',
    name: 'SignupOther',
    meta: {
      title: '其他网上报名',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/signupOther/index.vue')
  },
  {
    // 准考证打印
    path: '/printing',
    name: 'Printing',
    meta: {
      title: '准考证打印',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/printing/index.vue')
  },
  {
    // 成绩查询
    path: '/scoreQuery',
    name: 'ScoreQuery',
    meta: {
      title: '成绩查询',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/scoreQuery/index.vue')
  },
  {
    // 相关通知
    path: '/renotice',
    name: 'Renotice',
    meta: {
      title: '相关通知',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/renotice/index.vue')
  },
  {
    // 事业单位相关通知
    path: '/workRenotice',
    name: 'WorkRenotice',
    meta: {
      title: '事业单位相关通知',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/workRenotice/index.vue')
  },
  {
    // 其他相关通知
    path: '/renoticeOther',
    name: 'RenoticeOther',
    meta: {
      title: '其他相关通知',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/renoticeOther/index.vue')
  },
  {
    // 聘用公示
    path: '/publicity',
    name: 'Publicity',
    meta: {
      title: '聘用公示',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/publicity/index.vue')
  },
  {
    // 事业单位聘用公示
    path: '/workPublicity',
    name: 'WorkPublicity',
    meta: {
      title: '事业单位聘用公示',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/workPublicity/index.vue')
  },
  {
    // 其他聘用公示
    path: '/publicityOther',
    name: 'PublicityOther',
    meta: {
      title: '其他聘用公示',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/publicityOther/index.vue')
  },
  {
    // 考试报名延长时间
    path: '/extension',
    name: 'Extension',
    meta: {
      title: '考试报名延长时间',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('@/views/menus/extension/index.vue')
  },
  {
    // 招聘公告
    path: '/affichebm',
    name: 'Affichebm',
    meta: {
      title: '招聘公告',
      keepAlive: true, // 需要被缓存
      menus: true, //需不需要左侧的导航栏
    },
    component: () => import('../views/affichebm/affichepage.vue')
  },
  {
    // 招聘公告
    path: '/affichebz',
    name: 'Affichebz',

    meta: {
      title: '帮助中心',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/affichebz/affichepage.vue')
  },
  {
    // 招聘公告详情
    path: '/recruitdetaiks/:id',
    name: 'RecruitDetaiks',
    meta: {
      title: '事业单位招聘公告',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/recruitdetaiks/recruitdetaiks.vue')
  },
  {
    //帮助列表详情
    path: '/recruitdetaiksHelp/:id',
    name: 'RecruitDetaiksHelp',
    meta: {
      title: '事业单位招聘公告',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/recruitdetaiks/recruitdetaiksHelp.vue')
  },
  {
    // 关于我们详情
    path: '/aboutHelp',
    name: 'AboutHelp',
    meta: {
      title: '事业单位招聘公告',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('@/views/aboutHelp/index.vue')
  },
  {
    // 联系我们详情
    path: '/helpUs',
    name: 'HelpUs',
    meta: {
      title: '事业单位招聘公告',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('@/views/aboutHelp/helpUs/index.vue')
  },
  {
    // 报名系统页面
    path: '/applypage',
    name: 'applyPage',
    meta: {
      title: '系统报名',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/applypage/applyPage.vue')
  },
  {
    // 操作指引页面
    path: '/guidepage/:id',
    name: 'guidePage',
    component: () => import('../views/guidePage/guidePage.vue')
  },
  {
    // 操作指引页面
    path: '/logsPage',
    name: 'logsPage',
    meta: {
      title: '智考云人才测评管理系统',
      keepAlive: true, // 需要被缓存
    },
    component: () => import('../views/logsPage/index.vue')
  },
  {
    // 操作指引页面
    path: '/afficheDetails',
    name: 'afficheDetails',
    meta: {
      title: '智考云人才测评管理系统',
      keepAlive: false, // 需要被缓存
    },
    component: () => import('../views/afficheDetails/index.vue')
  },
  {
    // 绑定账号
    path: '/phoneLogin',
    name: 'phoneLogin',
    meta: {
      title: '智考云人才测评管理系统',
      keepAlive: false, // 需要被缓存
    },
    component: () => import('../views/phoneLogin/index.vue')
  }, {
    // 绑定成功
    path: '/phoneSuccess',
    name: 'phoneSuccess',
    meta: {
      title: '智考云人才测评管理系统',
      keepAlive: false, // 需要被缓存
    },
    component: () => import('../views/phoneLogin/phoneSuccess/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router