<template>
  <div class="tabs-list">
    <!-- tab组件 -->
    <div>
      <div class="left-title" style="display: flex; align-items: center">
        <img style="width: 60px" src="@/assets/images/logo.png" alt="" />
        人事考试报名系统
        <div style="font-size: 15px; color: #333333; padding-left: 24px">
          <div>业务电话：18198828037 0769-28631941</div>
          <div>工作日9:00-12:00，13:00-17:00</div>
        </div>
      </div>

      <div class="right-tabList">
        <div
          class="tab-item"
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ active: isActive === index }"
          @click="selectedBut(index, item.title)"
        >
          <router-link :to="{ path: item.url }">{{ item.title }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: [Array],
      delete: [],
    },
  },

  data() {
    return {
      isActive: 0, // 高亮下标
    };
  },
  methods: {
    selectedBut(i, ywType) {
      console.log(ywType, "ywType");
      this.isActive = i;
      if (ywType == "报名登记") {
        var href = this.$api + "#login";
        window.location = href;
      }
    },
  },

  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        console.log(newValue, "newValue头部标题");
        if (this.$route.meta.title == "首页") {
          this.isActive = 0;
        } else if (this.$route.query.title == "招聘公告") {
          this.isActive = 1;
        } else if (this.$route.query.title == "网上报名") {
          this.isActive = 2;
        } else if (this.$route.query.title == "准考证打印") {
          this.isActive = 3;
        } else if (this.$route.query.title == "成绩查询") {
          this.isActive = 4;
        } else if (this.$route.query.title == "聘用公示") {
          this.isActive = 5;
        } else if (
          this.$route.query.title == "帮助中心" ||
          this.$route.query.ywType == "帮助中心"
        ) {
          this.isActive = 7;
        } else {
          this.isActive = "";
        }
      },
    },
  },
};
</script>


<style scoped>
.tabs-list {
  width: 100%;
  background: #ffffff;
  padding: 20px 0px;
}

.tabs-list > div {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 102px;
}
.left-title {
  width: 40%;
  font-size: 1.9vw;
  color: hsl(9, 100%, 45%);
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.right-tabList {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.tab-item {
  height: 100%;
  font-size: 1.1vw;
  margin-left: 24px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.tab-item > a {
  height: 50px;
  line-height: 50px;
  display: inline-block;
  color: #e72100;
}
.tab-item.active > a {
  color: #e72100;
}
.tab-item.active > a::after {
  content: "";
  width: 20px;
  height: 2px;
  background: #e72100;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  z-index: 1111;
}

.tab-item a {
  color: #555555;
  text-decoration: none;
}
</style>