<template>
  <div class="buttom-cpmponents">
    <!-- 底部公共组件 -->
    <div class="content">
      <div class="companytop">
       <!-- <img
          class="img-logo"
          src="@/assets/images/logo1.png"
          alt=""
        />-->
		该系统由东莞市海基智远人力资源服务有限公司提供技术支持
      </div>
      <div class="btn-list">
        <div class="item" @click="aboutHelp">关于我们</div>
        |
        <div class="item" @click="aboutHelp1">联系我们</div>
        |
        <div class="item">报考流程</div>
      </div>

      <div class="buttom">
        <p>
          Copyright©1999-2022 人事机构 版权所有 本网站支持IPv6访问 <br />
          中华人民共和国增值电信业务经营许可证 经营许可证编号:A2.B1.B2-20100001
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 关于我们
    aboutHelp() {
      this.$router.push("/aboutHelp");
    },
    // 联系我们
    aboutHelp1() {
      this.$router.push("/helpUs");
    },
  },
};
</script>

<style scoped>
.buttom-cpmponents {
  width: 100%;
  border-top: 6px solid #e01811;
  display: flex;
  justify-content: center;
}
.companytop {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #333333;
  padding-bottom: 20px;
}
.img-logo {
  width: 30px;
  height: 30px;
  margin-right: 4px;
}

.content {
  width: 430px;
  /* height: 80px; */
  margin: 40px 0px;
}

.btn-list {
  color: #666666;
  display: flex;
  justify-content: space-evenly;
  margin: 0px 20px;
}

.item {
  color: #666666;
  font-size: 16px;
  cursor: pointer;
}

.buttom {
  color: #b2b2b2;
  font-size: 12px;
  text-align: center;
  margin-top: 24px;
}
</style>