<template>
  <div id="app">
    <navigationBar :tabList="topTabList" v-if="show" />
    <!-- 页面版心 -->
    <div>
      <Menutitles v-if="show"></Menutitles>
      <div :class="show ? 'view-container' : ''">
        <Menu></Menu>
        <!-- 路由出口 -->
        <keep-alive exclude="afficheDetails">
          <router-view />
        </keep-alive>
      </div>
    </div>
    <!-- 底部公共组件 -->
    <bottomComponents v-if="show" />
  </div>
</template>

<script>
import navigationBar from "./components/navigationBar.vue";
import bottomComponents from "./components/bottomComponents.vue";
import Menu from "./components/menu/index.vue";
import Menutitles from "./components/menutitles/index.vue";
export default {
  components: {
    navigationBar,
    bottomComponents,
    Menu,
    Menutitles,
  },
  data() {
    return {
      show: true,
      topTabList: [
        {
          title: "首页",
          url: "/",
        },
        {
          title: "招聘公告",
          url: "/affichebm?title=招聘公告",
        },
        {
          title: "网上报名",
          url: "/affichebm?title=网上报名",
        },
        {
          title: "准考证打印",
          url: "/affichebm?title=准考证打印",
        },
        {
          title: "成绩查询",
          url: "/affichebm?title=成绩查询",
        },
        {
          title: "聘用公示",
          url: "/affichebm?title=聘用公示",
        },
        {
          title: "报名登记",
          url: "/recommended",
        },
        {
          title: "帮助中心",
          url: "/affichebz?ywType=帮助中心",
        },
      ],
    };
  },

  created() {
    if (this._isMobile()) {
      console.log("手机端");
      var paths = ["/afficheDetails", "/phoneLogin", "/phoneSuccess"];
      this.show = false;
      //  这两个页面不需要重定向
      if (paths.indexOf(window.location.pathname) == -1) {
        this.$router.replace("/logsPage");
      }
    } else {
      console.log("pc端");
      this.show = true;
      // 重定向
      // this.$router.replace('/')
    }
  },

  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
#app {
  background: #f5f5f5;
  /* user-select: none; */
  padding: 0px;
  margin: 0px;
}
.view-container {
  padding: 0px 100px;
  display: flex;
  /* background-color: #ffffff; */
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #e01811 !important;
  color: #fff;
}
</style>
