<template>
  <div>
    <!-- 头部的路由标题 -->
    <div>
      <div class="affiche-top" v-if="$route.name != 'guidePage'">
        <span :class="classtitle" @click="$router.push('/')">首页</span>
        <div style="display: flex; align-items: center" v-if="shows">
          <img class="img-right" src="@/assets/images/right-arrow.png" alt="" />
          <span class="title">{{ contactTitle }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classtitle: "navigation-btn",
      contactTitle: "",
      shows: true,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        if (newValue.meta.title == "首页") {
          this.classtitle = "navigation-btnd";
          this.shows = false;
        } else {
          this.classtitle = "navigation-btn";
          this.contactTitle = newValue.meta.title;
          this.shows = true;
        }
      },
    },
  },
  mounted() {},
};
</script>

<style scoped>
.affiche-top {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 16px 80px;
  cursor: pointer;
}

.navigation-btn {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
}
.navigation-btnd {
  width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #e01811;
}

.arrow-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0px 12px;
}
.img-right {
  width: 20px;
  height: 25px;
  margin: 0 8px;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #e01811;
}
</style>