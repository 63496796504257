import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from "axios"
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
//配置公共方法
// use
Vue.use(mavonEditor)
Vue.prototype.$api = "https://ksbm.hj-blks.com"
Vue.prototype.axios = axios
Vue.config.productionTip = false

Vue.use(Vant);
Vue.use(ElementUI);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')